(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/utils/handlebars-helper/helpers/handlebars-helpers.js') >= 0) return;  svs.modules.push('/components/utils/handlebars-helper/helpers/handlebars-helpers.js');

(function() {
  'use strict';

  var isServerSide = typeof exports === 'object';
  var trinidad; var componentHelper;

  var isString = function(value) {
    return typeof value === 'string' || value instanceof String;
  };

  if (isServerSide) {
    trinidad = require('trinidad-core').core;
    componentHelper = trinidad.components.require('utils', 'component-helper').api;

    registerHelpers(require('hbs'), trinidad.config);
  } else {
    componentHelper = svs.utils.component_helper.helper;
    registerHelpers(Handlebars, { options: svs.core.config.data.svsconfig });
  }

  function registerHelpers(hbs, config) {
    hbs.registerHelper('extend', function(name, context) {
      if (this) {
        this.blocks = this.blocks || {};
        this.blocks[name] = this.blocks[name] || [];
        this.blocks[name].push(context.fn(this));
      }
    });

    hbs.registerHelper('block', function(name) {
      var blockData = '';
      if (this.blocks && this.blocks[name]) {
        blockData = this.blocks[name].join('\n');
        this.blocks[name] = [];
      }
      return blockData;
    });

    hbs.registerHelper('getAssetUrl', function(url) {
      return config.options.cdn + url;
    });

    hbs.registerHelper('getApiUrl', function(url) {
      if (svs.core && svs.core.jupiter && svs.core.jupiter.url) {
        return svs.core.jupiter.url() + url;
      }
      throw new Error('Jupiter config not loaded');
    });

    function isAbsoluteUrl(url) {
      return url && url.indexOf('http') === 0;
    }

    hbs.registerHelper('getCMSUrl', function(url) {
      return isAbsoluteUrl(url) ? url : config.options.cdn2 + url;
    });

    hbs.registerHelper('baseUrl', function(context) {
      if (isServerSide) {
        return context.data.root.baseUrl;
      }
      return svs.core.data.baseUrl;
    });

    hbs.registerHelper('iterate', function(context, options) {
      var fn = options.fn;
      var inverse = options.inverse;
      var data;
      var ret = '';

      if (options.data) {
        data = Handlebars.createFrame(options.data);
      }

      if (context && context.length > 0) {
        for (var i = 0, j = context.length; i < j; i++) {
          if (data) {
            data.index = i;
            data.listIndex = i + 1;
            data.first = i === 0;
            data.last = i === context.length - 1;
            data.even = i % 2 === 0;
          }
          ret = ret + fn(context[i], { data: data });
        }
      } else {
        ret = inverse(this);
      }
      return ret;
    });

    hbs.registerHelper('iterateString', function(context, options) {
      var fn = options.fn;
      var inverse = options.inverse;
      var ret = '';
      var data;

      if (options.data) {
        data = Handlebars.createFrame(options.data);
      }

      if (context && context.length > 0) {
        var contextArray = context.split('');
        for (var i = 0, j = contextArray.length; i < j; i++) {
          if (data) {
            data.index = i;
            data.listIndex = i + 1;
            data.first = i === 0;
            data.last = i === contextArray.length - 1;
            data.even = i % 2 === 0;
          }
          ret = ret + fn(contextArray[i], { data: data });
        }
      } else {
        ret = inverse(this);
      }
      return ret;
    });

    hbs.registerHelper('compare', function(lvalue, operator, rvalue, options) {
      var operators; var result;

      if (arguments.length < 3) {
        throw new Error('Handlerbars Helper compare needs 2 parameters');
      }

      if (options === undefined) {
        options = rvalue;
        rvalue = operator;
        operator = '===';
      }

      operators = {
        '==': function(l, r) {
          return l === r;
        },
        '===': function(l, r) {
          return l === r;
        },
        '!=': function(l, r) {
          return l !== r;
        },
        '!==': function(l, r) {
          return l !== r;
        },
        '<': function(l, r) {
          return l < r;
        },
        '>': function(l, r) {
          return l > r;
        },
        '<=': function(l, r) {
          return l <= r;
        },
        '>=': function(l, r) {
          return l >= r;
        },
        typeof: function(l, r) {
          return typeof l === r; 
        },
        '&&': function(l, r) {
          return l && r;
        },
        '||': function(l, r) {
          return l || r;
        }
      };

      if (!operators[operator]) {
        throw new Error('Handlerbars Helper compare doesnt know the operator ' + operator);
      }

      lvalue = typeof lvalue === 'function' ? lvalue.call(this) : lvalue;
      rvalue = typeof rvalue === 'function' ? rvalue.call(this) : rvalue;

      result = operators[operator](lvalue, rvalue);

      if (result) {
        return options.fn(this);
      }
      return options.inverse(this);
    });


    hbs.registerHelper('containsText', function(string, text, options) {
      if (string.indexOf(text) !== -1) {
        return options.fn(this);
      }
      return options.inverse(this);
    });

    hbs.registerHelper('isString', function(value, options) {
      if (isString(value)) {
        return options.fn(this);
      }
      return options.inverse(this);
    });

    hbs.registerHelper('everyNth', function(context, every, options) {
      var fn = options.fn;
      var inverse = options.inverse;
      var ret = '';
      if (context && context.length > 0) {
        for (var i = 0, j = context.length; i < j; i++) {
          var modZero = i % every === 0;
          ret =
            ret +
            fn(Object.keys(context[i]).reduce(
              function(acc, key) { 
                acc[key] = context[i][key];
                return acc;
              },
              {
                isModZero: modZero,
                isModZeroNotFirst: modZero && i > 0,
                isLast: i === context.length - 1
              }
            ));
        }
      } else {
        ret = inverse(this);
      }
      return ret;
    });

    hbs.registerHelper('debug', function(optionalValue) {
      var log;

      if (isServerSide) {
        log = trinidad.logger('trinidad:components');
      } else {
        log = new svs.core.log.Logger('svs.core.layout.handlebarsHelpers');
      }

      if (!optionalValue.hash && optionalValue) {
        log.debug('<' + optionalValue + '>');
        log.debug(eval('this.' + optionalValue)); 
        log.debug('</' + optionalValue + '>');
      } else {
        log.debug('<current context>');
        log.debug(this);
        log.debug('</current context>');
      }
    });

    hbs.registerHelper('printAll', function(obj, delimiter) {
      var DEFAULT_DELIMITER = ' ';

      return Object.keys(obj).reduce(function(acc, key) {
        return (
          acc +
          key +
          ': ' +
          obj[key] +
          (isString(delimiter) ? delimiter : DEFAULT_DELIMITER)
        );
      }, '');
    });

    hbs.registerHelper('ifAny', function() {
      var args = Array.prototype.slice.call(arguments);
      var options = args[args.length - 1];

      for (var i = 0; i < args.length - 1; i++) {
        if (args[i]) {
          return options.fn(this);
        }
      }

      return options.inverse(this);
    });

    hbs.registerHelper('ifAnyArray', function() {
      var args = Array.prototype.slice.call(arguments);
      var options = args[args.length - 1];

      for (var i = 0; i < args.length - 1; i++) {
        if (args[i]) {
          if (Array.isArray(args[i]) && args[i].length > 0) {
            return options.fn(this);
          }
        }
      }
      return options.inverse(this);
    });

    hbs.registerHelper('ifAll', function() {
      var args = Array.prototype.slice.call(arguments);
      var options = args[args.length - 1];

      for (var i = 0; i < args.length - 1; i++) {
        if (!args[i]) {
          return options.inverse(this);
        }
      }

      return options.fn(this);
    });

    hbs.registerHelper('isNull', function(value, options) {
      if (value === null) {
        return options.fn(this);
      }
      return options.inverse(this);
    });

    hbs.registerHelper('isDefined', function(value, options) {
      if (value !== undefined) {
        return options.fn(this);
      }
      return options.inverse(this);
    });

    hbs.registerHelper('array', function() {
      return Array.prototype.slice.call(arguments, 0, -1);
    });

    hbs.registerHelper('join', function() {
      var params = Array.prototype.slice.call(
        arguments,
        0,
        arguments.length - 1
      );
      var i = 0;
      var iLen = params.length;
      var res = '';

      for (; i < iLen; i++) {
        res += params[i];
      }
      return res;
    });

    hbs.registerHelper('for', function(from, to, increment, options) {
      var text = '';
      var i = from;
      var data;

      if (!options) {
        options = increment;
        increment = 1;
      }

      if (options.data) {
        var createFrame = hbs.createFrame || hbs.handlebars.createFrame;

        if (createFrame) {
          data = createFrame(options.data);
        }
      }

      for (; i <= to; i += increment) {
        if (data) {
          data.index = i;
        }

        text += options.fn(i, { data: data });
      }
      return text;
    });

    hbs.registerHelper('conditional', function() {
      if (arguments[0]) {
        return arguments[1];
      }
      return arguments[2];
    });

    hbs.registerHelper('ui', function() {
      return componentHelper.apply(this, [arguments, isServerSide ? 'ui' : svs.ui]);
    });

    hbs.registerHelper('component', function() {
      return componentHelper.apply(this, [arguments, isServerSide ? 'components' : svs.components]);
    });

    hbs.registerHelper('admin', function() {
      return componentHelper.apply(this, [arguments, isServerSide ? 'admin' : svs.admin]);
    });

    hbs.registerHelper('app', function() {
      return componentHelper.apply(this, [arguments, isServerSide ? 'app' : svs.app]);
    });

    hbs.registerHelper('isNativeApp', function(options) {
      if (config.options.isNativeApp) {
        return options.fn(this);
      }
      return options.inverse(this);
    });

    hbs.registerHelper('isSelfService', function(options) {
      if (config.options.isSelfService) {
        return options.fn(this);
      }
      return options.inverse(this);
    });
    hbs.registerHelper('useViewFitCover', function(options) {
      if (options.data && options.data.root && options.data.root.htmlClass && options.data.root.htmlClass.includes('viewfit-cover')) {
        return options.fn(this);
      }
      return options.inverse(this);
    });
    hbs.registerHelper('nl2br', function(text) {
      if (!text) {
        return '';
      }
      return text.replace(/\\n/g, '<br>');
    });
  }
})();


 })(window);